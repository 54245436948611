<template>
  <section>
    <div v-if="hasSubmit">
      <v-btn @click="getReport">Get Report</v-btn>
    </div>
    <div v-show="!isReportLoaded" class="center-progress">
      <v-progress-circular :size="50" color="primary" indeterminate />
      <p class="mt-5 primary--text">Loading Report...</p>
    </div>
    <div id="reportContainer" ref="reportContainer">
      <div class="d-flex justify-start">
        <span class="ml-3 text-h6 primary--text font-weight-bold">
          {{ reportName }}
        </span>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2"
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="toggleFullscreen()"
            >
              <v-icon> mdi-overscan </v-icon>
            </v-btn>
          </template>
          <span>See report in Fullscreen</span>
        </v-tooltip>
      </div>
      <div v-show="isReportLoaded" id="report" ref="report" />
    </div>
  </section>
</template>

<script>
import { refreshDefaultData } from '@/modules/reports/_factories/embedReports/defaultEmbed.js';
import api from '../_api';
import powerBiReportfactory from '../_factories/powerBiReportFactory.js';
import { mapGetters } from 'vuex';

export default {
  name: 'PowerBIReport',
  props: {
    reportName: { type: String, required: true },
    siteId: { type: String, required: false },
    hasSubmit: { type: Boolean, default: false },
    controlFriendlyName: { type: String, default: '' },
  },

  data() {
    return {
      embedConfiguration: null,
      refreshRate: 30000,
      isRealTimeRefreshing: false,
      timeoutId: null,
      isDashboard: false,
      intervalId: null,
      isReportLoaded: false,
    };
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
    clearInterval(window.refreshTokenInterval);
    this.$route.meta.isRealTimeReport = false;
  },

  watch: {
    reportName: function (newVal) {
      if (!this.hasSubmit && newVal && newVal !== '') {
        this.getReport();
      }
    },
    reportInfo: {
      handler(value) {
        if (value && /real\s*-?\s*time/gi.test(value.reportType)) {
          this.$route.meta.isRealTimeReport = true;
          if (!this.isDashboard) {
            if (this.intervalId) {
              clearInterval(this.intervalId);
            }
            this.intervalId = setInterval(() => {
              try {
                refreshDefaultData();
              } catch (error) {
                console.error('Error refreshing data: ', error);
              }
            }, this.refreshRate);
          }
        } else {
          this.$route.meta.isRealTimeReport = false;
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      reportInfo: 'ViewSupportStore/ReportsStore/getReportInfo',
    }),
  },

  created() {
    this.getReport();
  },

  methods: {
    getReport: async function () {
      const embedConfiguration = await api.getPowerBiEmbedConfiguration(
        this.reportName,
        this.siteId
      );

      this.embedConfiguration = embedConfiguration;

      if (embedConfiguration.type === 'dashboard') {
        this.isDashboard = true;
      } else {
        this.isDashboard = false;
      }

      if (this.controlFriendlyName) {
        embedConfiguration.reportParameters.SensorName =
          this.controlFriendlyName;
      }

      const container = document.getElementById('report');
      container.addEventListener('loaded', () => {
        this.isReportLoaded = true;
      });

      powerBiReportfactory.getPowerBiReportStrategy(
        this.reportName,
        container,
        embedConfiguration
      );
    },
    toggleFullscreen() {
      const container = this.$refs.reportContainer;
      if (!document.fullscreenElement) {
        if (container.requestFullscreen) {
          container.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
  },
};
</script>

<style>
section {
  height: 100%;
}

#report {
  height: 95%;
  width: auto;
}

#report iframe {
  border: none;
}

#reportContainer {
  position: absolute;
  top: 0;
}

.center-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
